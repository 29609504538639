<template>
  <b-card v-if="mounted" bg-variant="light">
    <span v-for="(table, index) in tables"  :key="index">
      <b-card 
        align="center"
        footer-tag="header"
        border-variant="secondary"
        header-border-variant="secondary"
      >
        <br>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="table.tableHtml"></span>

        <template #header>
          <b-button
            variant="flat-primary"
            @click.stop="scrollToRef(table.id)"
          >
            View in Article
          </b-button>
        </template>
      </b-card>
    </span>
  </b-card>
</template>

<script>

import {
  BCard, BButton
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
  },
  props: {
    tablesInHtml: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      tables: null,
      mounted: null,
    }
  }, 
  watch: { 
  }, 
  mounted () { 
    this.tables = []
    for (let i = 0; i < this.tablesInHtml.length; i++) {
      const tableHtml =  this.tablesInHtml[i]
      const id = tableHtml.split('<pbm-table-wrapper><div id="')[1].split('"')[0]
      this.tables.push({ 
        id, tableHtml
      })
    }
    this.mounted = true
  },
  methods: { 
    scrollToRef (refName) {
      
      // xml markup has id attribute and  not have ref attribute
      //const element = this.$refs[refName]
      const element = document.getElementById(refName)
      console.log('scrolling to elel', element)
      //element.scrollIntoView({ behavior: 'smooth' })
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })

      this.$emit('CloseSidebarRequested')

    },
  },
}
</script> 
