<template>
  <span v-if="contributors && independentNotes">
    <b-card v-if="contributors" title="Authors and Affiliations">
      <span v-for="(contributor, index) in contributors"  :key="index">
        <contributor-detail
          :contrib="contributor"
          :affiliates="affiliates"
          :author-notes="authorNotes"
          :selection="selection"
        /> 
        <br v-if="index < contributors.length - 1">
      </span>
    </b-card>
    <b-card v-if="independentNotes.length > 0" title="Notes" bg-variant="light">
      <span v-for="(note, index) in independentNotes"  :key="index">
        <author-note
          :note="note"
        /> 
      </span>
    </b-card>
  </span>
</template>

<script>

import {
  BCard, 
} from 'bootstrap-vue'

import ContributorDetail from './ContributorDetail.vue'
import AuthorNote from './AuthorNote.vue'

export default {
  components: {
    BCard, 
    ContributorDetail,
    AuthorNote,
  },
  props: {
    contributors: {
      type: Array,
      default: null
    },
    affiliates: {
      type: Array,
      default: null
    },
    authorNotes: {
      type: Array,
      default: null
    },
    selection: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      independentNotes: null,
    }
  }, 
  computed: {
  },
  watch: { 
    selection () {
      //console.log('contrib', this.contrib)
      const excludeDict = {}
      for (let i = 0; i < this.contributors.length; i++) {
        const contrib = this.contributors[i]
        if (!contrib['xref-fn']) continue
        for (let j = 0; j < contrib['xref-fn'].length; j++) {
          const contribFN = contrib['xref-fn'][j]
          excludeDict[contribFN.rid] = contribFN
        }
      }
      this.independentNotes = []
      if (!this.authorNotes) return
      for (let i = 0; i < this.authorNotes.length; i++) {
        const note = this.authorNotes[i]
        //console.log(99999, note.attrs.id, excludeDict[note.attrs.id])
        if (!excludeDict[note.attrs.id]) {
          //console.log(11111)
          this.independentNotes.push(note)
        }
      }
    },
  }, 
  mounted () { 
    
  },
  methods: { 

  },
}
</script>
