<template>
  <b-card v-if="mounted" bg-variant="light">
    <span v-for="(equation, index) in equations"  :key="index">
      <b-card 
        align="center"
        footer-tag="header"
        border-variant="secondary"
        header-border-variant="secondary"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="equation.equationHtml"></span>

        <template #header>
          <b-button
            variant="flat-primary"
            @click.stop="scrollToRef(equation.id)"
          >
            View in Article
          </b-button>
        </template>
      </b-card>
    </span>
  </b-card>
</template>

<script>

import {
  BCard, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BButton,
  },
  props: {
    equationsInHtml: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      equations: null,
      mounted: null,
    }
  }, 
  watch: { 
  }, 
  mounted () { 
    this.equations = []
    for (let i = 0; i < this.equationsInHtml.length; i++) {
      const equationHtml =  this.equationsInHtml[i]
      const id = equationHtml.split('<pbm-equation-wrapper><span id="')[1].split('"')[0]
      this.equations.push({ 
        id, equationHtml
      })
    }
    this.mounted = true
  },
  methods: { 
    //this one called from sidebar
    // also see scroll to ref in XrefTag.vue which is called when viewing article web page.
    scrollToRef (refName) {
      
      // xml markup has id attribute and  not have ref attribute
      //const element = this.$refs[refName]
      const element = document.getElementById(refName)
      console.log('scrolling to elel', element)
      //element.scrollIntoView({ behavior: 'smooth' })
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })

      this.$emit('CloseSidebarRequested')

    },
  },
}
</script>
