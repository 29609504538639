<template>
  <span v-if="mounted && contributors"> 
    <span v-for="(item, index) in authors.list" :key="index">
      <a v-if="item.more" href="#" @click="authorSelected(null)"> 
        ... 
        <b-badge variant="primary">
          All ({{ authors.count }})
        </b-badge>
        ...
      </a>
      <a v-else href="#" @click="authorSelected(item.selectionId)"> 
        {{ nameOn(item) }} 
      </a>
      <a v-if="item.contribId && item.contribId.type == 'orcid'" :href="item.contribId.text" target="_blank"> 
        <b-img :src="require('@/assets/images/pubmill/ORCIDiD_icon16x16.png')"/>
      </a>
      <a v-if="item['xref-corresp']" :href="'mailto:'.concat(emailOn(item['xref-corresp'].rid))"> 
        <feather-icon icon="MailIcon" style="margin-left:.3rem;"/>
      </a>
      <span v-if="index < authors.list.length - 1">, </span>
      <span v-if="index == authors.list.length - 2"> and </span>
    </span>
  
    <b-sidebar 
      id="contrib-sidebar-right" 
      v-model="isSidebarOpen"
      bg-variant="white"
      right
      backdrop
      shadow
      width="480px"
    >
      <contributors-sidebar
        :contributors="contributors"
        :affiliates="affiliates"
        :author-notes="authorNotes"
        :selection="authorSelectedId"
        @CloseSidebarRequested="isSidebarOpen = false"
      /> 
    </b-sidebar>
  </span>
</template>
 
<script>

import detailUtil from '@/views/pubmill/detailUtil'

import {
  BBadge, BImg, BSidebar, VBToggle,
} from 'bootstrap-vue'
 
import ContributorsSidebar from './ContributorsSidebar.vue'

export default {

  components: {
    BBadge, 
    BImg,
    BSidebar, 
    ContributorsSidebar,
  }, 
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    article: {
      type: Object,
      default: null
    },  
  },
  data () {
    return {
      contributors: null,
      affiliates: null,
      authorNotes: null,
      mounted: null,
      authorSelectedId: null,
      isSidebarOpen: false,
    }
  },
  computed: {
    authors () {
      const authorsDict = { list: [] }
      let current = 0
      let moreAdded = null
      for (let i = 0; i < this.contributors.length; i++) {
        const contrib = this.contributors[i]
        if (contrib.type !== 'author') continue
        current += 1
        if (current > 3 && current < this.contributors.length) {
          if (!moreAdded) {
            authorsDict.list.push({ more: 'y' })
            moreAdded = true
          }
        } else {
          authorsDict.list.push(contrib)
        }
      }
      authorsDict.count = current
      return authorsDict
    },
  },
  mounted () { 

    const contribGroup = detailUtil.contribGroupOn(this.article)
    this.authorNotes = contribGroup.authorNotes
    this.contributors = contribGroup.contributors
    this.affiliates = contribGroup.affiliates

    //console.log('aaaaaaaaaaaaaaaaaa in article-detail-contributors', contribGroup.contributors)

    this.mounted = true
  },
  methods: { 
    authorSelected (selectionId) {
      this.isSidebarOpen = true
      this.authorSelectedId = { value: selectionId }
    },
    emailOn (rid) {
      let correspXML = ''
      for (let i = 0; i < this.authorNotes.length; i++) {
        const an = this.authorNotes[i]
        if (an.attrs.id === rid) {
          correspXML = an.xml
          break
        }
      }
      let emails = ''
      //console.log('ddff', correspXML.split('<email>'))
      const nodes = correspXML.split('<email>')
      for (let i = 0; i < nodes.length; i++) {
        if (i === 0) continue
        const node = nodes[i]
        let delim = '</email>'
        if (!node.includes(delim)) delim = '/>'
        if (i > 1)  emails = emails.concat(',')
        emails = emails.concat(node.split(delim)[0])
      }
      return emails
    },
    nameOn (contrib) {
      return contrib['given-names'].concat(' ', contrib.surname)
    },
  }
}
</script>
