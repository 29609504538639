<template>
  <div>
    <div v-if="error404">
      <br><br>
      <h2> {{ error404 }} </h2>
    </div>
    <div v-if="article && fileName">
      <b-row>
        <b-col cols="12">
          <span v-if="inProduction"> 
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
              Production
            </h5>
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
              {{ articleSummary.article_type }}
            </h5>
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
              <router-link :to="archiveIssueRouterName"> Volume: {{ volume }} - Issue: {{ issue }}</router-link>
              / {{ articleSummary.doi }}
            </h5>
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;">            
              <router-link :to="articleAdminRouterName">Admin View</router-link>
            </h5>
            <h5 v-if="reviewsExist" class="float-left">            
              <router-link :to="reviewSessionsRouterName">Current Reviews</router-link>
            </h5>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              style="margin-top:-.7rem;"
              variant="flat-primary"
              small
              class="btn-icon"
              @click.stop="snapshotReviewSession()"
            >
              <feather-icon icon="CameraIcon" />
              Snapshot a review session
            </b-button>
          </span>
          <span v-else>
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
              Published
            </h5>
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
              {{ articleSummary.article_type }}
            </h5>
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
              <router-link :to="archiveVolRouterName">Volume: {{ volume }}</router-link>
              / 
              <router-link :to="archiveIssueRouterName">Issue: {{ issue }}</router-link>
              / {{ articleSummary.doi }}
            </h5>
            <h5 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;">            
              <router-link :to="articleAdminRouterName">Admin View</router-link>
            </h5>
            <span class="float-left pr-1 mb-0" style="margin-right:1rem;">
              <h5 v-if="reviewsExist" class="float-left">              
                <router-link :to="reviewSessionsRouterName">Current Reviews</router-link>
              </h5>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                style="margin-top:-.7rem;"
                variant="flat-primary"
                small
                class="btn-icon"
                @click.stop="snapshotReviewSession()"
              >
                <feather-icon icon="CameraIcon" />
                Snapshot a review session
              </b-button>
            </span>
          </span>
        </b-col>      
      </b-row>
      <article-detail-web  
        :article="article" 
        :article-summary="articleSummary"
        :volume="volume"
        :issue="issue"
        :has-tables="tablesDatasetsVideosMathML && tablesDatasetsVideosMathML[0] != null"
        :has-mathml="tablesDatasetsVideosMathML && tablesDatasetsVideosMathML[1] != null"
        :has-datasets="tablesDatasetsVideosMathML && tablesDatasetsVideosMathML[2] != null"
        :has-videos="tablesDatasetsVideosMathML && tablesDatasetsVideosMathML[3] != null"
      />
    </div>
  </div>
</template> 
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import detailUtil from '@/views/pubmill/detailUtil'
 
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import ArticleDetailWeb from './ArticleDetailWeb.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    ArticleDetailWeb,
  },
  directives: {
    Ripple,
  },

  data () {
    return {
      volume: null,
      issue: null,
      fileName: null,
      tabIndex: 0,
      article: null,
      articleSummary: null,
      reviewsExist: null,
      allHistory: null,
      filteredHistory: null,
      xsdValidationError: null,
      genericSchematronErrors: null,
      literatumSchematronErrors: null,
      literatumSchematronWarnings: null,
      affNameNumDict: {},
      affiliationList: [],
      showUpdateActivity: true,
      showPublishingActivity: true,
      showNotes: true,
      showWarnings: true,
      error404: null
    }
  },
  computed: {  

    tablesDatasetsVideosMathML () {
      return detailUtil.tablesDatasetsVideosMathML(this.articleSummary)
    },
    totalValidationErrors () {
      return detailUtil.totalValidationErrors(this.xsdValidationError, this.genericSchematronErrors, this.iteratumSchematronErrors)           
    },
    totalValidationWarnings () {
      return detailUtil.totalValidationWarnings(this.articleSummary, this.literatumSchematronWarnings)          
    },
    inProduction () {
      return this.$route.path.includes('production-web')
    },
    published () {
      return (!this.inProduction)
    }, 
    archiveVolRouterName () {
      return {
        name: 'publication-archive-admin-'.concat(this.volume), 
        params: {
          issn: this.$route.params.issn,
          vol: this.volume,
        }
      }
    },
    archiveIssueRouterName () {
      const returnDict = {
        params: {
          issn: this.$route.params.issn,
          issue: this.issue,
        }
      }
      if (this.inProduction) {
        returnDict.name = 'production-issue-admin'
        returnDict.params.vol = this.volume
      } else {
        returnDict.name = 'publication-issue-admin'
        returnDict.params.vol = this.volume
      }
      return returnDict
    },
    articleAdminRouterName () {
      const returnDict = {
        params: {
          issn: this.$route.params.issn,
          vol: this.articleSummary.volume,
          issue: this.articleSummary.issue,
          file_name: this.articleSummary.file_name.split('.xml')[0],
        }
      }
      returnDict.name = 'article-publication-detail-admin'
      if (this.inProduction) {
        returnDict.name = 'article-production-detail-admin'
      }
      //console.log('xxxx', returnDict)
      return returnDict
    },
    reviewSessionsRouterName () {
      const returnDict = {
        params: {
          issn: this.$route.params.issn,
          vol: this.articleSummary.volume,
          issue: this.articleSummary.issue,
          file_name: this.articleSummary.file_name.split('.xml')[0],
        }
      }
      returnDict.name = 'article-review-sessions'
      return returnDict
    },
    
    articlePublishedOnline () {
      return detailUtil.articlePublishedOnline(this.article)
    },
    articlePublishedPrint () {
      return detailUtil.articlePublishedPrint(this.article)
    }
  },
  watch: {
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  mounted () {
    this.volume = this.$route.params.vol
    this.issue = this.$route.params.issue
    this.fileName = this.$route.params.file_name
    this.refreshData()
  },
  methods: {
    resolveArticleXMLDetail () {
      console.log('ArticleDetailMainvue ffffffxxxxxxxxx')
      if (this.$route.params.doi_prefix) {
        this.searchByDOI(this.$route.params.doi_prefix.concat('/', this.$route.params.doi_suffix))
        return   
      }

      const issn =  this.$route.params.issn
      let fileStatus = 'published'
      if (this.inProduction) {
        fileStatus = 'production'
      }
      this.getArticleXMLDetail(issn, fileStatus)
    },
    searchByDOI (doi) {
      publicationApi.searchByDOI(doi, this.$route.params.issn, this, null)
    },
    backFromSearchByDOI (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        this.error404 = serverData.error
      } else if (serverData.hits.total.value > 0) {
        const result = serverData.hits.hits[0]
        this.volume = result.source.volume
        this.issue = result.source.issue 
        this.fileName = result.id

        let fileStatus = 'published'
        if (this.inProduction) {
          fileStatus = 'production'
        }
        this.getArticleXMLDetail(this.$route.params.issn, fileStatus)
      } else {
        this.error404 = '404: DOI not found'
      }
    },
    getArticleXMLDetail (issn, fileStatus) {
      const queryParams = {
        issn,
        volume: this.volume,
        issue: this.issue,
        file_status: fileStatus,
        file_name: this.fileName.concat('.xml'),
        web_view: 'y'
      }
      //console.log('xxxxxxxxx', queryParams)
      publicationApi.getArticleXMLDetail(queryParams, this, null)
    },

    backFromGetArticleXMLDetail (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        console.log('zzzWWWbackFromGetArticleXMLDetail', serverData)
        this.articleSummary = serverData.articleSummary
        
        const tmp = []
        if (serverData.audit && serverData.audit.changes) {
          for (let i = 0; i < serverData.audit.changes.length; i++) {
            const rec = serverData.audit.changes[i]
            rec.type = 'changes'
            tmp.push(rec)
          }
        }
        if (serverData.audit && serverData.audit.staging) {
          for (let i = 0; i < serverData.audit.staging.length; i++) {
            const rec = serverData.audit.staging[i]
            rec.type = 'staging'
            tmp.push(rec)
          }
        }
        if (serverData.audit && serverData.audit.notes) {
          for (let i = 0; i < serverData.audit.notes.length; i++) {
            const rec = serverData.audit.notes[i]
            rec.type = 'notes'
            tmp.push(rec)
          }
        }

        tmp.sort((a, b) => {
          if (a.timestamp > b.timestamp) {
            return -1
          }
          if (a.timestamp < b.timestamp) {
            return 1
          }
          return 0
        })
        //console.log('aaaaaaa', tmp)

        this.xsdValidationError = detailUtil.xsdValidationError(serverData)
        this.genericSchematronErrors = detailUtil.genericSchematronErrors(serverData)
        // console.log('zzbbddda', serverData.literatumSchematronValidationResult)
        this.literatumSchematronErrors = detailUtil.literatumSchematronErrors(serverData)
        this.literatumSchematronWarnings = detailUtil.literatumSchematronWarnings(serverData)

        /* 
        let cnt = 1
        for (const i in serverData.article_json.author) {
          const auth = serverData.article_json.author[i]
          for (const j in auth.affiliation) {
            const affName = auth.affiliation[j].name
            if (!this.affNameNumDict[affName]) {
              this.affNameNumDict[affName] = cnt.toString()
              this.affiliationList.push([cnt.toString(), affName])
              cnt += 1
            }
          }
        }
        */
        const currentPublicationName = this.$store.state.pubmillGlobalStore.currentPublicationObject.name
        if (!currentPublicationName) {
          console.log('currentPublicationName not found resetting from issn', this.$route.params.issn)
          this.$store.commit('pubmillGlobalStore/updateCurrentPublicationId', this.$route.params.issn)
        }

        this.article = serverData.article_json
        this.reviewsExist = serverData.reviews_exist
        //this.article.body = this.article.body.split('Latex converted to MathML')[1].split('<inline-formula>')[1].split('</inline-formula>')[0]
        //this.article.body = this.article.body.split('Latex converted to MathML')[1]
        //this.article.body = this.article.body.split('Math Tests Original Latex')[1]
        //this.article.body = this.article.body.split('<body xmlns:mml="http://www.w3.org/1998/Math/MathML" xmlns:xlink="http://www.w3.org/1999/xlink">')[1]
        //console.log('ggg', this.article.body)
   
      } 
    },
    snapshotReviewSession () {
      let fileStatus = 'published'
      if (this.inProduction) {
        fileStatus = 'production'
      }
      const queryParams = {
        issn: this.$route.params.issn,
        volume: this.volume,
        issue: this.issue,
        file_status: fileStatus,
        file_name: this.fileName.concat('.xml'),

      }
      //console.log('xxxxxxxxx', queryParams)
      publicationApi.snapshotReviewSession(queryParams, this, null)

    },

    backFromSnapshotReviewSession (serverData) {
      if (serverData.error) {
        console.log('ERROR: backFromSnapshotReviewSession', serverData.error)
      } else {
        //console.log('backFromSnapshotReviewSession', serverData)
        this.$router.push(this.reviewSessionsRouterName) 
      } 
    },
    refreshData () {
      this.resolveArticleXMLDetail()
    }
  },
}
</script>

<style lang="scss">
.pill_gap {
    margin-right:5px;
}
</style>
