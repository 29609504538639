<template>
  <div v-if="pdfUrl"> 
    <iframe height="1000" width="100%" :src="getFilePath"></iframe>
  </div>
</template>

<script>

import fileMgrApi from '@/dl_pubmill/apis/fileMgr'

export default {

  components: {

  },

  props: {
    article: {
      type: Object,
      default: null
    },   
  },
  data () {
    return {
      pdfUrl: null,
      path: '/pdfjs-2.7.570-dist/web/viewer.html'
    } 
  },
  computed: { 
    getFilePath () {
      return this.path.concat('?file=', encodeURIComponent(this.pdfUrl))
    }
  }, 
  mounted () { 
    this.getDownloadLink(this.article.pdf_name, 'pdf', this.article)
  },
  methods: {
    getDownloadLink (file, folder, article) {
      console.log('aaaaa', article)
      const queryParams = {
        issn: this.$route.params.issn,
        content_type: folder,
        file_name: file
      }

      if (this.$route.path.includes('production-')) {
        queryParams.file_status = 'production'
        queryParams.volume =  article.volume
        queryParams.issue = article.issue
        queryParams.id_folder = article.doi
      } else {
        queryParams.file_status = 'published'
        queryParams.volume =  article.volume
        queryParams.issue = article.issue
        queryParams.id_folder = article.doi
      }

      fileMgrApi.getDownloadLink(queryParams, this, null)
    },

    backFromGetDownloadLink (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        console.log('ggggggg', serverData)
        this.pdfUrl = serverData.url.concat('&origin=', window.location.host)
      } 
    }, 
  }
}
</script>
