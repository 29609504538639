<template>
  <section v-if="mounted && note">
   
    <b-card
      :ref="'noteId'.concat(note.attrs.id)"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p v-html="$sanitize(note.xml)"></p>
    </b-card>
  </section>
</template>

<script>

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {

    BCard,
  },
  props: {
    note: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      mounted: null,
    }
  }, 
  computed: {
  },
  watch: { 
  }, 
  mounted () { 
    this.mounted = true
  },
  methods: { 
  },
}
</script>
