<template>
  <section v-if="figure"> 
    <b-card 
      border-variant="secondary"
      header-border-variant="secondary"
      header-tag="header" 
      footer-tag="footer"
    >
      <template #header>
        {{ figure.title }}
        <b-button
          variant="flat-primary"
          @click.stop="scrollToRef(figure.id)"
        >
          View in Article
        </b-button>
      </template>
      <br> 
      <b-card 
        align="center"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="figure.imgHtml"></span>
      </b-card>
      <template #footer>

        <span v-if="showCaption">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="figure.captionHtml"></span>
        </span>
      </template>
    </b-card>
  </section>
</template>
 
<script> 

import {
  BCard, BButton,
} from 'bootstrap-vue'

export default {

  components: {
    BCard,
    BButton,
  }, 
  props: {
    figure: {
      type: Object,
      default: null
    },
    showCaption: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
    }
  },
  methods: { 
    //this one called from sidebar
    // also see scroll to ref in XrefTag.vue which is called when viewing article web page.
    scrollToRef (refName) {
      
      // xml markup has id attribute and  not have ref attribute
      //const element = this.$refs[refName]
      const element = document.getElementById(refName)
      console.log('scrolling to elel', refName, element)
      //element.scrollIntoView({ behavior: 'smooth' })
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })

      this.$emit('CloseSidebarRequested') 

    },
  },
}
</script>
