<template>
  <section v-if="mounted"> 
    <br>
    <b-card>
      <b-row>
        <b-col :cols="9">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <h4 v-if="articleSummary.title && articleSummary.title.length > 0" v-html="$sanitize(articleSummary.title)"></h4>
          <h4 v-else class="text-danger"> Title Missing</h4>
          <article-web-contributors  
            :article="article" 
          />  
        </b-col>
      </b-row>
      <br>
      <b-row v-if="instrumentedAbstracts.length > 0"><b-col cols="9">
        <b-card bg-variant="light">
          <span v-for="(abstractHtml, index0) in instrumentedAbstracts"  :key="index0">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="abstractHtml"></span>
            <br>
          </span>
        </b-card>
      </b-col> </b-row> 
      <b-row>
        <b-col :cols="9"> 
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="instrumentedBody"></span>
        </b-col>
        <b-col :cols="3">
          <div :class="navClass">
            <b-nav
              vertical
              small
              class="wrap-border"
            >
              <b-nav-item 
                v-if="figuresInHtml && figuresInHtml.length > 0"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-toggle.sidebar-right
                @click="selected = 'figures'"
              >
                <feather-icon icon="ImageIcon" />
                Figures
              </b-nav-item>

              <b-nav-item
                v-if="tablesInHtml && tablesInHtml.length > 0"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-toggle.sidebar-right
                @click="selected = 'tables'"
              >
                <feather-icon icon="ColumnsIcon" />
                Tables
              </b-nav-item>

              <b-nav-item 
                v-if="equationsInHtml && equationsInHtml.length > 0"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-toggle.sidebar-right
                @click="selected = 'equations'"
              >
                <feather-icon icon="DivideCircleIcon" />
                Equations
              </b-nav-item>

              <b-nav-item 
                v-if="hasDatasets"
                @click="selected = 'datasets'"
              >
                <feather-icon icon="LayersIcon" />
                Datasets
              </b-nav-item>

              <b-nav-item 
                v-if="hasVideos"
                @click="selected = 'videos'"
              >
                <feather-icon icon="YoutubeIcon" />
                Videos  
              </b-nav-item>
            </b-nav>

            <br><br>

            <b-nav 
              vertical
              small
              class="wrap-border"
            > 
              <b-nav-item 
                v-for="(header, index) in articleHeaders"  :key="index"
                @click="headerSelected(header.id)"
              >
                <span v-if="pillStatusOn(header.id)"><b><small>{{ headerTitle (header.title) }}</small></b></span>
                <span v-else><small>{{ headerTitle (header.title) }}</small></span>
              </b-nav-item>
            </b-nav>
          </div>
        </b-col>
      </b-row> 
      <br>
      <b-row v-if="acknowlegments.length > 0"><b-col cols="9">
        <b-card bg-variant="light">
          <h4 id="back-acknowlegements">Acknowledgments</h4>
          <span v-for="(ack, index0) in acknowlegments"  :key="index0">
            <div>
              <span v-for="(child, index) in ack.children"  :key="index">
                <tag-dispatcher
                  v-if="child.type === 'tag'"
                  :tag="child"
                  parent="Acknowledgments"
                  selected="article"
                  :images="webImgeDict"
                />
                <span v-else-if="child.type === 'text'">
                  {{ child.value }}
                </span>
              </span> 
            </div>
            <br>
          </span>
        </b-card>
      </b-col> </b-row> 
      <br>
      <b-row v-if="backFootnotes.length > 0"><b-col cols="9">
        <b-card bg-variant="light">
          <h4 id="back-footnotes">Footnotes</h4>
          <span v-for="(fn, index0) in backFootnotes"  :key="index0">
            <div>
              <span v-for="(child, index) in fn.children"  :key="index">
                <tag-dispatcher
                  v-if="child.type === 'tag'"
                  :tag="child"
                  parent="Footnotes"
                  selected="article"
                  :images="webImgeDict"
                />
              </span> 
            </div>
            <br>
          </span>
        </b-card>
      </b-col> </b-row> 
      <br>
      <b-row v-if="backSections.length > 0"><b-col cols="9">
        <b-card bg-variant="light">
          <span v-for="(sec, index0) in backSections"  :key="index0">
            <h4 :id="sec.id">{{ sec.name }}</h4>
            <div>
              <span v-for="(child, index) in sec.children"  :key="index">
                <tag-dispatcher
                  v-if="child.type === 'tag'"
                  :tag="child"
                  parent="Back"
                  selected="article"
                  :images="webImgeDict"
                />
              </span> 
            </div>
            <br>
          </span>
        </b-card>
      </b-col> </b-row> 
      <br>
      <b-row v-if="references && references.length > 0"><b-col cols="9">
        <b-card bg-variant="light">
          <h4 id="back-references">References</h4>
          <br>
          <span v-for="(ref, index_ref) in references"  :key="index_ref">
            <b-row>
              <b-col cols="1">
                <span style="padding-left:1rem;">
                  <b-button 
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    square
                    size="sm"
                    variant="outline-primary"
                    href="#"
                    style="margin:-1rem;"
                    @click="activateReferences('pbm-body-'.concat(ref.id))"
                  >
                    <feather-icon icon="CornerLeftUpIcon" />
                    <span :id="'bibr-'.concat(ref.id)"> {{ ref.label }}</span>
                  </b-button>
                </span>
              </b-col>
              <b-col v-if="ref.collab && ref.collab[0]" cols="11" >
                {{ ref.collab[0].value }}
              </b-col>
              <b-col v-if="ref.people" cols="11" >
                <span v-for="(person, index_ref_p) in ref.people.children" :key="index_ref_p">
                  <span v-if="person['given-names']"> {{ person['given-names'] }} {{ person.surname }}, </span>
                </span>
                <span v-if="ref['publisher-name']">
                  {{ ref['publisher-name'][0].value }}
                </span>
                <span v-if="ref['publisher-loc']">
                  {{ ref['publisher-loc'][0].value }}
                </span>
                <span v-if="ref['article-title']">{{ ref['article-title'][0].value }}, </span>
                <span v-if="ref.source"> <i>{{ ref.source[0].value }}</i>, </span>
                <span v-if="ref.volume"><b>{{ ref.volume[0].value }}</b>, </span>
                <span v-if="ref.fpage"> {{ ref.fpage[0].value }}</span>
                <span v-if="ref.lpage">-{{ ref.lpage[0].value }}</span>
                <span v-if="ref.year">({{ ref.year[0].value }}).</span>
              </b-col>
            </b-row>
            <hr>
            <br>
          </span>
        </b-card>
      </b-col> </b-row> 
      <br>
 
      <article-detail-pdf v-if="showPDF" :article="articleSummary"/>

      <b-sidebar 
        id="sidebar-right" 
        ref="sidebar"
        bg-variant="white"
        right
        backdrop
        shadow
        width="960px" 
      >
        <figures-sidebar
          v-if="selected === 'figures'" 
          :figures-in-html="figuresInHtml"
          @CloseSidebarRequested="$refs.sidebar.hide()"
        /> 
        <tables-sidebar
          v-if="selected === 'tables'"
          :tables-in-html="tablesInHtml"
          @CloseSidebarRequested="$refs.sidebar.hide()"
        /> 
        <equations-sidebar
          v-if="selected === 'equations'"
          :equations-in-html="equationsInHtml"
          @CloseSidebarRequested="$refs.sidebar.hide()"
        /> 
      </b-sidebar>
    </b-card>
  </section>
</template>
 
<script>

//import debounce from 'lodash.debounce'
import fileMgrApi from '@/dl_pubmill/apis/fileMgr'
import detailUtil from '@/views/pubmill/detailUtil'

import {
  BButton, BCard, BRow, BCol, BNav, BNavItem, BSidebar, VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import ArticleWebContributors from './ArticleWebContributors.vue'
import FiguresSidebar from './FiguresSidebar.vue'
import TablesSidebar from './TablesSidebar.vue'
import EquationsSidebar from './EquationsSidebar.vue'
import ArticleDetailPdf from './ArticleDetailPdf.vue'
 
export default {

  components: {
    BButton,
    BCard,
    BRow, 
    BCol,
    BNav,
    BNavItem,
    ArticleWebContributors,
    ArticleDetailPdf,
    //components that may be recursive can be done this way
    //https://stackoverflow.com/questions/49154490/did-you-register-the-component-correctly-for-recursive-components-make-sure-to
    TagDispatcher: () => import('./tags/TagDispatcher.vue'),

    BSidebar,
    FiguresSidebar,
    TablesSidebar,
    EquationsSidebar,
  },  
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  props: {
    article: {
      type: Object,
      default: null
    },
    articleSummary: {
      type: Object,
      default: null
    },
    hasTables: {
      type: Boolean,
    },
    hasMathml: {
      type: Boolean,
    },
    hasDatasets: {
      type: Boolean,
    },
    hasVideos: {
      type: Boolean,
    },
    volume: {
      type: String,
      default: null
    },
    issue: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      webImgeDict: null,
      figuresInHtml: null,
      tablesInHtml: null,
      equationsInHtml: null,
      imageNames: null,
      selected: 'article',
      instrumentedAbstracts: null,
      instrumentedBody: null,
      backSections: null,
      backFootnotes: null,
      acknowlegments: null,
      articleHeaders: null,
      references: null,
      mounted: null,
      xxx: '<mml:math id="i1"><mml:msub><mml:mrow><mml:mi mathvariant="script">H</mml:mi></mml:mrow><mml:mrow><mml:mi>e</mml:mi></mml:mrow></mml:msub></mml:math>'
    }
  }, 
  computed: {
    showPDF() {
      if (this.article.abstract && this.article.abstract.length > 0) return false
      if (this.article.body) return false
      return true
    },
    navClass() {
      return 'stickyNavbar'
    },
    inProduction () {
      return this.$route.path.includes('production-web')
    },
  },
  watch: {
  }, 
  mounted () { 
    //console.log('ArticleDetailWeb mounted', window.MathJax)
    //window.addEventListener('scroll', this.handleDebouncedScroll)
    
    this.getWebImageUrls()
    this.articleHeaders = []
    this.initAbstracts()
    this.initBody()
    this.initTables()
    this.initEquations()
    this.initMiscBack()
    this.references =  detailUtil.referencesOn(this.article)
    if (this.references && this.references.length > 0) {
      this.articleHeaders.push({ id: 'back-references', title: 'References' })
    }
  },

  updated () { 
    //console.log('UPDATED lifecycle hook after mounted to ensure MathJax rendering')
    window.MathJax.typeset()
  },
  methods: { 
    activateMathJax () {
      setTimeout(() => this.mathJaxReady(), 500) 
    },
    activateReferences(id) {
      setTimeout(() => this.refReady(id), 500) 
    },
    refReady (id) {
      //console.log('tttttt ref ready', id, this.$refs[id])
      this.scrollToRef(id)
    },
    mathJaxReady () {
      //console.log('tttttt ref ready', id, this.$refs[id])
      window.MathJax.typeset()
    },
    headerTitle (title) {
      if (title.length > 25) {
        return title.substring(0, 25).concat('...')
      } 
      return title
    },
    pillStatusOn (refName) {
      if (this.selected === refName) {
        return true
      }
      return false
    },
    imageLinkOn (imageName) {
      const rec = this.webImgeDict[imageName]
      if (rec) {
        return rec.url 
      } 
      return ''
    },
    initAbstracts () {
      this.instrumentedAbstracts = []
      if (!this.article.abstract) return
      for (let i = 0; i < this.article.abstract.length; i++) {
        const abstract = this.article.abstract[i]
        this.instrumentedAbstracts.push(abstract)
        console.log('aaaaaaaaaa', abstract)
        let id = 'pbm-jats-abstract'
        let name = 'Abstract'

        if (abstract.split('</h4>')[0].includes('-executive-summary')) {
          id = id.concat('-executive-summary')
          name = 'Significance'
        }
        this.articleHeaders.push({ id, title: name })
        //this.initFiguresFromAbstract(this.articleBodyChildren)

      }
    },
    initBody () { 
      this.instrumentedBody = this.article.body
      if (!this.instrumentedBody || this.instrumentedBody.length === 0) return
      const nodes =   this.instrumentedBody.split('<pbm-section-')
      if (nodes.length < 2) return
      for (let i = 0; i < nodes.length; i++) {
        if (i === 0) continue
        const sect = nodes[i].split('</pbm-section')[0]
        const id = sect.split('>')[0]
        const title = sect.split('<h4>')[1].split('</h4>')[0]
        //console.log('bbbbbb', id, title)
        this.articleHeaders.push({ id, title })
      }
    },
    initTables () {
      this.tablesInHtml = [] 
      //collect all tables for sidebar
      if (!this.instrumentedBody || this.instrumentedBody.length === 0) return
      const nodes =   this.instrumentedBody.split('<pbm-table-wrapper>')
      for (let i = 0; i < nodes.length; i++) {
        if (i === 0) continue
        const wrapper = '<pbm-table-wrapper>'.concat(nodes[i].split('</pbm-table-wrapper>')[0], '</pbm-table-wrapper>')
        const newWrapper = wrapper.replace('<table>', '<table width="98%" style="margin: 10px;">')
        this.instrumentedBody = this.instrumentedBody.replace(wrapper, newWrapper)
        this.tablesInHtml.push(newWrapper)
      }
      this.mounted = true
    }, 
    initEquations () {
      this.equationsInHtml = [] 
      if (!this.instrumentedBody || this.instrumentedBody.length === 0) return
      //collect all equations for sidebar
      const nodes =   this.instrumentedBody.split('<pbm-equation-wrapper>')
      for (let i = 0; i < nodes.length; i++) {
        if (i === 0) continue
        const wrapper = '<pbm-equation-wrapper>'.concat(nodes[i].split('</pbm-equation-wrapper>')[0], '</pbm-equation-wrapper>')
        this.equationsInHtml.push(wrapper)
      }
      this.mounted = true
    }, 
    initFiguresAndInlineImagesInBody () {
      this.figuresInHtml = [] 
      //'<pbm-img-wrapper pbm-img-name="pnas.2116730119fig01"></pbm-img-wrapper>'
      //find and replace all pbm-img-wrapper  ( these will be within figures or inline) 
      let nodes =   this.instrumentedBody.split('<pbm-img-wrapper ')
      for (let i = 0; i < nodes.length; i++) {
        if (i === 0) continue
        const wrapper = '<pbm-img-wrapper '.concat(nodes[i].split('</pbm-img-wrapper>')[0], '</pbm-img-wrapper>')
        const imgName = wrapper.split('pbm-img-name="')[1].split('">')[0]
        const src =  this.imageLinkOn(imgName)
        const newWrapper = '<pbm-img-wrapper '.concat('pbm-img-name="', imgName, '"> <img src="', src, '" style="padding: 10px;" class="img-fluid">', '</pbm-img-wrapper>')
        this.instrumentedBody = this.instrumentedBody.replace(wrapper, newWrapper)
      }

      //collect all figures for sidebar
      nodes =   this.instrumentedBody.split('<pbm-figure-wrapper>')
      for (let i = 0; i < nodes.length; i++) {
        if (i === 0) continue
        const wrapper = '<pbm-figure-wrapper>'.concat(nodes[i].split('</pbm-figure-wrapper>')[0], '</pbm-figure-wrapper>')
        this.figuresInHtml.push(wrapper)
      }
      this.mounted = true
    }, 
    initMiscBack () {
      this.acknowlegments = []
      this.backFootnotes = []
      this.backSections = []
      if (!this.article['miscl-back']) return
      if (this.article['miscl-back'].ack && this.article['miscl-back'].ack.length > 0) {
        this.acknowlegments = this.article['miscl-back'].ack
        this.articleHeaders.push({ id: 'back-acknowlegements', title: 'Acknowlegments' })
      }
      if (this.article['miscl-back'].footnotes && this.article['miscl-back'].footnotes.length > 0) {
        this.backFootnotes = this.article['miscl-back'].footnotes
        this.articleHeaders.push({ id: 'back-footnotes', title: 'Footnotes' })
      }
      for (let i = 0; i < this.article['miscl-back'].sections.length; i++) {
        const section = this.article['miscl-back'].sections[i]
        const id = 'back-section-'.concat(i)
        //console.log('tttt section', id, section)
        let name = section.type
        let sectionChildren = section.children 
        if (section.children[0].tagName === 'title') {
          name = section.children[0].children[0].value
          sectionChildren = []
          for (let j = 0; j < section.children.length; j++) {
            if (j === 0) continue
            sectionChildren.push(section.children[j])
          }
        } else if (section.children[0].tagName === 'supplementary-material') {
          name = 'Supporting Information'
          sectionChildren = []
          for (let j = 0; j < section.children.length; j++) {
            if (j === 0) continue
            sectionChildren.push(section.children[j])
          }
        }
        this.backSections.push({ 
          id, 
          name, 
          type: section.type, 
          children: 
          sectionChildren 
        })
        this.articleHeaders.push({ id, title: name })
      }
    },
    headerSelected (refName) {
      this.selected = refName
      this.scrollToRef(refName)
    },
    scrollToRef (refName) {
      //const element = this.$refs[refName]
      const element = document.getElementById(refName)
      console.log('Article Detail Web scrolling to elel', refName, element)
      //element.scrollIntoView({ behavior: 'smooth' })
      //element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      const yOffset = -100
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: 'smooth' })
      
    },
    getWebImageUrls () {
      this.imageNames = []

      for (let i = 0; i < this.articleSummary.graphics_in_xml.length; i++) {
        let xlink =  this.articleSummary.graphics_in_xml[i].xlink
        
        let delim = '.tif'
        if (!xlink.endsWith(delim)) {
          delim = '.jpeg'
          if (!xlink.endsWith(delim)) {
            delim = '.gif'
            if (!xlink.endsWith(delim)) {
              delim = null
            }
          }
        }
        if (delim != null) {
          xlink = xlink.split(delim)[0]
        }
        this.imageNames.push(xlink)
      }

      if (this.imageNames.length === 0) {
        this.mounted = true
        return
      }
  
      let fileStatus =  'published'
      if (this.inProduction) {
        fileStatus = 'production'
      }
      const queryParams = {
        issn: this.$route.params.issn,
        volume: this.volume,
        issue: this.issue,
        doi: this.articleSummary.doi,
        file_status: fileStatus,
        image_names: this.imageNames,
        size: 'large'
      } 
      fileMgrApi.getWebImageUrls(queryParams, this, null)
    },
    backFromGetWebImageUrls (serverData) {
      if (serverData.error) {
        console.log('eeeeee', serverData.error)
      } else {
        //console.log('iiiiii', serverData)
        this.webImgeDict =  serverData
        if (!this.instrumentedBody) return
        this.initFiguresAndInlineImagesInBody()
      } 
    },
  }, 
}
</script>
<style scoped>
  .stickyNavbar {
    overflow: hidden;
    position: fixed;
    top: 170px;
  }
</style>
