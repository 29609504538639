<template>
  <b-card v-if="mounted" bg-variant="light">
    <b-row><b-col>
      <b-button
        variant="flat-primary"
        @click.stop="showCaptions = !showCaptions"
      >
        <span v-if="showCaptions">Hide Captions</span>
        <span v-else>Show Captions</span>
      </b-button>
    </b-col></b-row>
    <br>
    <b-row>
      <b-col v-for="(figure, index) in figures"  :key="index" cols="6" >
        <figure-component
          :figure="figure"
          :show-caption="showCaptions" 
          @CloseSidebarRequested="closeSidebar"
        /> 
        <br>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'

import FigureComponent from './FigureComponent.vue'

export default {
  components: {
    FigureComponent,
    BButton,
    BCard,
    BRow, 
    BCol,
  },
  props: {
    figuresInHtml: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      showCaptions: false,
      figures: null,
      mounted: null,
    }
  }, 
  computed: {
  },
  watch: { 
  }, 
  mounted () { 
    this.figures = []
    for (let i = 0; i < this.figuresInHtml.length; i++) {
      const figHtml =  this.figuresInHtml[i]
      const id = figHtml.split('<pbm-figure-wrapper><div id="')[1].split('"')[0]
      let title = ''
      if (figHtml.includes('</h5>')) {
        title = figHtml.split('<h5')[1].split('</h5>')[0].split('>')[1]
      } else if (figHtml.includes('</H5>')) {
        title = figHtml.split('<H5')[1].split('</H5>')[0].split('>')[1]
      } else if (figHtml.includes('</h4>')) {
        title = figHtml.split('<h4')[1].split('</h4>')[0].split('>')[1]
      } else if (figHtml.includes('</H4>')) {
        title = figHtml.split('<H4')[1].split('</H4>')[0].split('>')[1]
      } 
        
      const imgHtml =  figHtml.split('<pbm-img-wrapper ')[1].split('</pbm-img-wrapper>')[0]
      let captionHtml = null
      if (figHtml.includes('<small>')) {
        captionHtml =  '<small>'.concat(figHtml.split('<small>')[1].split('</small>')[0], '</small>')
      }
      this.figures.push({ 
        id, title, imgHtml, captionHtml 
      })
    }
    this.mounted = true
  },
  methods: { 
    closeSidebar () {
      this.$emit('CloseSidebarRequested')
    },
  },
}
</script>
